import React from 'react'
import { MDBCol, MDBCard } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"

class CardPartners extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : 'auto'
    return (
      <MDBCol lg={this.props.collg} md={this.props.colmd} className="pb-card d-flex" >
        <MDBCard className="flex-fill outline card-hover">
          {this.props.image && (
            <GatsbyImage
              top
              image={this.props.image}
              overlay="white-slight"
              hover
              waves
              alt={this.props.alttext}
              className="rounded-top drop-shadow"
            />
          )}
        </MDBCard>
      </MDBCol>
    )
  }
}

export default CardPartners