import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import Cta from '../components/cta'
import AltSection from '../components/columnAlternating'
import CardPartners from '../components/cardPartners.js'
import CardIcons from '../components/cardIcons.js'
import Card from '../components/card'
import SolutionText from '../components/solutionText'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'

const VisualCommunicationsTemplate = ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images ?.fallback?.src }
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div
                className={`font-w-400 text-xs-extra-large divlink ${ post.html ? 'text-opening-para' : 'text-opening-para-mb0' }`}
                dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }}
              />
              <div
                className="mt-3 richtext divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      {post.frontmatter.section && (
        <section className="bg-gray-light">
          <MDBContainer>
            {post.frontmatter.section.map((sections, index) => (
              <AltSection
                key={index}
                title={sections.title}
                subtitle={sections.subtitle}
                description={sections.description}
                image={sections.image.childImageSharp.gatsbyImageData}
                alt={sections.alttext}
                placement={sections.placement}
                titleClass="font-alt font-w-400 letter-spacing-1 mb-3 pt-0 title-xs-medium title-medium text-very-dark-blue"
              />
            ))}
          </MDBContainer>
        </section>
      )}

      {post.frontmatter.bragroll && (
        <section className="bg-light-blue">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                {post.frontmatter.bragroll.title}
              </h2>
              {post.frontmatter.bragroll.card && (
                <MDBRow>
                  <>
                    {post.frontmatter.bragroll.card.map((cards, index) => (
                      <CardIcons
                        key={index}
                        collg="4"
                        colmd="6"
                        title={cards.title}
                        subtitle={cards.subtitle}
                        image={cards.image.childImageSharp.gatsbyImageData}
                        alt={cards.alttext}
                      />
                    ))}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}

      {post.frontmatter.partner && (
        <section className="bg-gray-light">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                {post.frontmatter.partner.title}
              </h2>
              {post.frontmatter.partner.card && (
                <MDBRow>
                  <>
                    {post.frontmatter.partner.card.map((cards, index) => (
                      <CardPartners
                        key={index}
                        collg="4"
                        colmd="6"
                        image={cards.image.childImageSharp.gatsbyImageData}
                        alttext={cards.alttext}
                      />
                    ))}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}

      {post.frontmatter.avplat && (
        <>
          {post.frontmatter.avplat.map((avplats, index) => (
            <Fragment key={index}>
              {avplats.type === 'text' && (
                <SolutionText data={avplats} sectionClass={avplats.class} />
              )}
            </Fragment>
          ))}
        </>
      )}

      {post.frontmatter.helpfulresources && (
        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {' '}
                {post.frontmatter.helpfulresources.title}{' '}
              </h2>

              {post.frontmatter.helpfulresources.helpfulres && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.helpfulresources.helpfulres.map(
                      (helpfulres) => {
                        return (
                          <Card
                            collg="4"
                            colmd="6"
                            height="9rem"
                            title={helpfulres.title}
                            subtitle={helpfulres.subtitle}
                            description={helpfulres.description}
                            image={ helpfulres.image.childImageSharp.gatsbyImageData }
                            alttext={helpfulres.alttext}
                            placement={helpfulres.placement}
                            link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                            titleclass="title-small"
                            descriptionClass="text-card-small py-2"
                          />
                        )
                      }
                    )}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}

      {post.frontmatter.cta && (
        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      )}
    </Layout>
  )
}
export default VisualCommunicationsTemplate

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "visual-communications" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        name
        title
        subtitle
        ctatext
        ctaslug
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        alttext
        section {
          title
          subtitle
          placement
          description
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, quality: 90)
            }
          }
          alttext
        }
        partner {
          title
          card {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
        bragroll {
          title
          card {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }
        avplat {
          title
          description
          class
          type
        }
        cta {
          title
          description
          linktext
          link
        }
        template
      }
    }
  }
`
